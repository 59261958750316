// @ts-check
/* eslint-disable id-length, array-callback-return, import/max-dependencies */

// Still remaining
// * Videos in a pop-up
// * Remove active  attr when item is no longer in view
// * Rework isInViewPort to make so x amount of pixels are in view
import { fromEvents } from 'kefir';
import __ from 'ramda/src/__';
import lt from 'ramda/src/lt';
import compose from 'ramda/src/compose';
import cond from 'ramda/src/cond';
import defaultTo from 'ramda/src/defaultTo';
import F from 'ramda/src/F';
import filter from 'ramda/src/filter';
import last from 'ramda/src/last';
import lensPath from 'ramda/src/lensPath';
import map from 'ramda/src/map';
import not from 'ramda/src/not';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import split from 'ramda/src/split';
import view from 'ramda/src/view';
import T from 'ramda/src/T';

import { getDomElements, percentageInViewport, setLocation } from './utils';

/**
 * @typedef {Object} TrackingEventDetails
 * @property {string} args
 * @property {string} eventName
 * @property {boolean} [openNewTab]
 * @property {boolean} [forceDownload]
 */

// -- CONSTANTS --
// ---------------

const delayTime = 5000;
const sectionActiveAttr = 'data-track-active';
const trackingSectionsActive = `[data-section-event][${sectionActiveAttr}]`;
const trackingSectionsNotActive = `[data-section-event]:not([${sectionActiveAttr}])`;

const eventNames = {
  ended: 'Video Completed',
  play: 'Video Played'
};

const eventTypes = {
  button: 'Button Clicked',
  gallery: 'Gallery Image Clicked',
  image: 'Image Clicked',
  story: 'Story List Button Clicked'
};

// -- halfInView : DomNode -> Boolean
const isInViewport = percentageInViewport(50);

// -- notInViewPort : Array DomNode -> Bool
const notInViewport = compose(not, isInViewport);

// -- VIDEO HELPERS

// -- getTrackingEventName : String -> String
const getTrackingEventName = compose(defaultTo('Unknown Event'), prop(__, eventNames));

// -- getVideoSrc : DomNode -> String
const getVideoSrc = view(lensPath(['childNodes', 0, 'src']));

// -- getVideoFileName : String -> String
const getVideoFileName = compose(last, split('/'), getVideoSrc);

// --  getVideoTrackAttr : DomNode -> String
const getVideoTrackAttr = node => node.getAttribute('data-track-video') || 'unknown-section';

// -- transformTrackingInformation : Event -> { eventName, args }
const transformTrackingInformation = videoEvent => ({
  args: getVideoFileName(videoEvent.target),
  eventName: `${getVideoTrackAttr(videoEvent.target)}:${getTrackingEventName(videoEvent.type)}`
});

// -- eventTypeIsEnded : Event -> Bool
const eventTypeIsEnded = propEq('type', 'ended');

// -- getCurrentTime : Event -> Number
const getCurrentTime = compose(prop('currentTime'), prop('target'));

// -- videoHasJustStartedPlaying : Event -> Bool
const videoHasJustStartedPlaying = compose(lt(0.5), getCurrentTime);

// -- filterVideoSkips : Event -> Bool
// prettier-ignore
const filterVideoSkips = cond([
  [eventTypeIsEnded, T],
  [videoHasJustStartedPlaying, F],
  [T, T]
]);

// LINK TRACKING HELPERS
/**
 * @param {MouseEvent} clickEvent
 * @returns {TrackingEventDetails}
 */
const transformClickEventToTracking = clickEvent => {
  const isTargetBlank = clickEvent.target.target === '_blank';
  const isDownloadable = clickEvent.target.hasAttribute('download');

  if (!isTargetBlank && !isDownloadable) {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  }

  const eventType = clickEvent.target.getAttribute('data-section-type');
  const eventSection = clickEvent.target.getAttribute('data-event');
  const eventName = eventTypes[eventType];
  const args = clickEvent.target.getAttribute('href');

  return {
    args,
    eventName: `${eventSection}:${eventName}`,
    forceDownload: isDownloadable,
    openNewTab: isTargetBlank
  };
};

/**
 * @param {TrackingEventDetails} param0
 * @returns {TrackingEventDetails}
 */
const sendTrackingEvent = ({ args, eventName, forceDownload, openNewTab }) => {
  if (window.sendPtiEventData) {
    window.sendPtiEventData(null, null, eventName, args);
  }

  return { args, eventName, forceDownload, openNewTab };
};

const startTracking = () => {
  // -- VIDEO TRACKING
  getDomElements('video[data-track-video]').map(video => {
    const onEnded$ = fromEvents(video, 'ended');

    fromEvents(video, 'play')
      .merge(onEnded$)
      .filter(filterVideoSkips)
      .map(transformTrackingInformation)
      .onValue(sendTrackingEvent);
  });

  // SECTION TRACKING
  const onScroll$ = fromEvents(window, 'scroll');

  onScroll$
    .debounce(500)
    .map(() => getDomElements(trackingSectionsActive))
    .map(filter(notInViewport))
    .onValue(map(el => el.removeAttribute(sectionActiveAttr)));

  onScroll$
    .debounce(1000)
    .map(() => getDomElements(trackingSectionsNotActive))
    .map(filter(isInViewport))
    .delay(delayTime)
    .map(filter(isInViewport))
    .onValue(
      map(sectionInView => {
        sectionInView.setAttribute(sectionActiveAttr, '');

        sendTrackingEvent({
          args: null,
          eventName: `${sectionInView.getAttribute('data-section-event')}:Viewed`
        });
      })
    );

  // LINK TRACKING
  getDomElements('[data-section-event]').map(section => {
    fromEvents(section, 'click')
      .filter(
        ({ target }) =>
          target.hasAttribute('data-event') &&
          target.hasAttribute('data-section-type') &&
          target.nodeName === 'A'
      )
      .map(transformClickEventToTracking)
      .map(sendTrackingEvent)
      .onValue(({ args, openNewTab, forceDownload }) => {
        if (forceDownload) {
          return;
        }

        if (openNewTab) {
          window.open(args, '_blank').focus();
          return;
        }

        setLocation(args);
      });
  });
};

export { startTracking };
