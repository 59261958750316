// @ts-check
/* eslint-disable camelcase, sort-keys, no-shadow, require-unicode-regexp, new-cap */
import { Just, Nothing } from 'crocks/Maybe';
import safe from 'crocks/Maybe/safe';
import Result from 'crocks/Result';
import isNumber from 'crocks/predicates/isNumber';
import curry from 'ramda/src/curry';
import compose from 'ramda/src/compose';
import ifElse from 'ramda/src/ifElse';
import invoker from 'ramda/src/invoker';
import isNil from 'ramda/src/isNil';
import pipe from 'ramda/src/pipe';

const { Ok, Err } = Result;
/**
 * @param {URLSearchParams} searchParams
 * @param {string} key
 * @returns {string}
 */
const getSearchParamValue = (searchParams, key) => searchParams.get(key) ?? '';

/**
 * @param {string} url
 * @returns {string}
 */
const getUtmSearchParams = url => {
  const { searchParams } = new URL(url);

  const utmSource = getSearchParamValue(searchParams, 'utm_source');
  const utmCampaign = getSearchParamValue(searchParams, 'utm_campaign');
  const gclid = getSearchParamValue(searchParams, 'gclid');
  const gclsrc = getSearchParamValue(searchParams, 'gclsrc');

  return `${utmSource}|${utmCampaign}|${gclid}|${gclsrc}`;
};

/**
 * @param {number} delayMS - Time delay in ms
 * @return {Promise<void>}
 */
const delay = delayMS => new Promise(res => setTimeout(res, delayMS));

/**
 * @template T
 * @param {T extends Function} obj
 * @returns {boolean}
 */
const isFunction = obj => {
  return Boolean(obj && obj.constructor && obj.call && obj.apply);
};

/**
 * @param {Gtag.Gtag} gtag - The Gtag object
 * @param {string} adsCode - Our Google Ads code
 * @param {string} conversionLabel - a unique id per-form.
 * @param {string} email - a unique id per-form.
 * @param {() => void} cb - callback once Google Ads has fired
 * @returns {void}
 */
const fireConversion = (gtag, adsCode, conversionLabel, email, cb) => {
  // If the user is using an ad-blocker there is
  // a chance the event_callback will not fire.
  // In this case, force the callback to be invoked after a second.
  delay(1000).then(cb);

  if (isFunction(gtag) && adsCode && conversionLabel) {
    gtag('event', 'conversion', {
      send_to: `${adsCode}/${conversionLabel}`,
      event_callback: cb
    });
  }

  if (isFunction(window.sendPtiEventData) && conversionLabel) {
    window.sendPtiEventData(email, false, 'conversion', conversionLabel);
  }
};

// -- percentageInViewport : Number -> DomNode -> Boolean
const percentageInViewport = threshold => elem => {
  // Threshold is the percentage of the element we want in view
  const pixelsInView = numberOfPixelsInViewport(elem);
  const { height } = elem.getBoundingClientRect();
  const percentageInView = Math.floor((pixelsInView / height) * 100);

  return percentageInView >= threshold;
};

// -- parseJson : a -> Result (Err String)
const parseJson = json => {
  try {
    return Ok(JSON.parse(json));
  } catch (e) {
    return Err(e.message);
  }
};

// -- parseSafeInt: String -> Maybe Number
const parseIntSafe = pipe(parseInt, safe(isNumber));

/**
 * @param {string} str
 * @returns {DocumentFragment}
 */
const makeHtml = str => document.createRange().createContextualFragment(str);

// -- numberOfPixelsInViewport : DomNode -> Number
const numberOfPixelsInViewport = elem => {
  const { bottom, height, top } = elem.getBoundingClientRect();
  const { clientHeight } = document.documentElement;
  const topY = Math.min(height, clientHeight - top);
  const bottomY = bottom < clientHeight ? bottom : clientHeight;

  return Math.max(0, top > 0 ? topY : bottomY);
};

// -- $$ : String -> undefined | HTMLElement
const $$ = document.querySelector.bind(document);

// -- $$$ : String -> NodeList
const $$$ = document.querySelectorAll.bind(document);

// -- addListener : String -> Fn -> HTMLElement
const addListener = curry((type, fn, el) => el.addEventListener(type, fn));

// -- fromNullable : a -> Maybe a
const fromNullable = ifElse(isNil, Nothing, Just);

/**
 * @param {string} selector
 * @returns {any}
 */
const getDomElement = selector => compose(fromNullable, $$)(selector);

/**
 * @param {string} selector
 * @returns {HTMLElement[]}
 */
const getDomElements = selector => compose(xs => Array.prototype.slice.call(xs), $$$)(selector);

// -- setLocation : String -> IO
const setLocation = newLocation => window.location.assign(newLocation);

// -- pause : DomNode -> ()
const pause = invoker(0, 'pause');

// -- pause : DomNode -> ()
const play = invoker(0, 'play');

// -- base64ToUtf8 : String -> String
const base64EncodeToUtf8 = str =>
  btoa(
    // eslint-disable-next-line no-unused-vars
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_match, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

// -- base64ToUtf8 : String -> String
const base64DecodeToUtf8 = str =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(str), char => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

/**
 *  @param {number} hours
 *  @returns {string}
 */
const getHours = hours => {
  if (hours === 12) {
    return '12';
  } else if (hours === 0) {
    return '00';
  }

  return (hours % 12).toString();
};

/**
 *  @param {string} iso8601
 *  @returns {string}
 */
const getDisplayTime = iso8601 => {
  const date = new Date(new Date(iso8601).getTime());
  const hours = getHours(date.getHours());
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = date.getHours() < 12 ? 'am' : 'pm';

  return `${hours}:${minutes}${period}`;
};

export {
  $$,
  addListener,
  base64EncodeToUtf8,
  base64DecodeToUtf8,
  delay,
  fireConversion,
  getDisplayTime,
  getDomElement,
  getDomElements,
  getUtmSearchParams,
  isFunction,
  makeHtml,
  parseJson,
  percentageInViewport,
  parseIntSafe,
  pause,
  play,
  setLocation
};
