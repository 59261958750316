// @ts-check
/* eslint-disable no-param-reassign, no-shadow, no-unused-expressions, no-return-assign, max-statements, id-length */
import MicroModal from 'micromodal';

import { getDomElement, makeHtml } from './utils';

/**
 * @typedef {Object} CookieSection
 * @property {string} title
 * @property {string} text
 * @property {boolean | null} inputChecked
 */

/**
 * @param {string} cookieValue
 * @returns {Promise<void|Response>}
 */
const updateCookiePrefs = cookieValue =>
  fetch('/api/cookie-preferences', {
    body: cookieValue,
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post'
  }).catch(console.error);

const cookieMessage = `PageTiger uses necessary cookies to make our site work. We'd also like to set optional analytics cookies to help us improve it. We won't set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your preferences.`;

const setModalContent = ({ title = '', content = '' }) => {
  document.querySelector('#modal-title').textContent = title;
  document.querySelector('#modal-content').innerHTML = content;
};

/**
 * @param {CookieSection} cookieSection
 * @returns {string}
 */
const renderCookieSection = ({ title, text, inputChecked }) => {
  const hasInput = typeof inputChecked === 'boolean';
  const isChecked = inputChecked === true;
  const titleLowered = title.toLocaleLowerCase();
  const cookieDescriptionClass = inputChecked === true ? 'enabled' : 'disabled';
  const enabledDisabledText = isChecked ? 'Enabled' : 'Disabled';

  return `
  <div class="cookie-detail-section">
    <div class="cookie-detail-section-head">
      <h3 class="cookie-detail-section-heading">${title}</h3>
        ${
          hasInput
            ? `
            <div class="cookie-detail-input-container">
              <input id="cookies-${titleLowered}" class="js-cookie-input-${titleLowered} visually-hidden cookie-detail-input" ${
                isChecked ? 'checked' : ''
              } type="checkbox">
              <label for="cookies-${titleLowered}" class="cookie-detail-label mod-${cookieDescriptionClass}" aria-label="Toggle ${title} Cookies">
                <span class="cookie-detail-input-text mod-${cookieDescriptionClass}" style="display: block; margin-top: 4px;">${enabledDisabledText}</span>
              </label>
            </div>`
            : `<span class="cookie-detail-input-text mod-enabled">Always Enabled</span>`
        }
    </div>
    <p class="cookie-detail-section-text">${text}</p>
  </div>`;
};

/**
 * @param {{advertising: Boolean, functional: Boolean, performance: Boolean }} pageTurn
 * @return {string}
 */
const getCookieMarkup = ({ advertising, functional, performance }) => `
  <form class="cookie-form  js-cookie-form" data-t="cookie-modal">
    <div class="cookie-section">
        <span class="cookie-text">${cookieMessage}</span>

        <div class="cookie-detail">
          ${renderCookieSection({
            inputChecked: null,
            text: 'These cookies are essential in order to enable you to move around the site and use its features, such as accessing secure areas of the site. Without these cookies, services you have asked for cannot be provided.',
            title: 'Necessary'
          })}

          ${renderCookieSection({
            inputChecked: performance,
            text: 'Performance cookies are generally third-party cookies from vendors we work with or who work on our behalf that collect information about your visit and use of the PageTiger website, for instance which pages you visit the most often, and if you get error messages from web pages.',
            title: 'Performance'
          })}

          ${renderCookieSection({
            inputChecked: functional,
            text: 'Functionality cookies allow a site to remember choices you make (such as your user name, language or the region you are in) and provide more enhanced, personal features.',
            title: 'Functional'
          })}

          ${renderCookieSection({
            inputChecked: advertising,
            text: 'Third-party advertising cookies are used to help measure the effectiveness of our advertising campaigns and website features.',
            title: 'Advertising'
          })}

        </div>

        <div class="cookie-detail-buttons">
          <button onclick="this.form.submitted=this.value;" value="accept" class="cookie-button mod-tertiary js-accept-all" type="submit" data-t="cookie-accept-all" aria-label="Accept All Cookies">Accept All</button>
          <button onclick="this.form.submitted=this.value;" value="reject" class="cookie-button mod-tertiary js-toggle-cookie-settings" data-t="cookie-settings" type="submit" aria-label="Reject All Cookies">Reject All</button>
          <button class="cookie-button mod-primary js-accept-all" type="submit" data-t="cookie-accept-all" aria-label="Accept Choosen Cookies">Confirm Choices</button>
        </div>
    </div>
</form>
`;

const cookieModal = () => {
  const {
    advertising = false,
    functional = false,
    performance = false
  } = window.cookieValues || {};

  MicroModal.show('modal');

  setModalContent({
    content: getCookieMarkup({
      advertising,
      functional,
      performance
    }),
    title: 'Cookie Preferences'
  });

  const $$ = document.querySelector.bind(document);
  const advertisingInput = $$('.js-cookie-input-advertising');
  const functionalInput = $$('.js-cookie-input-functional');
  const performanceInput = $$('.js-cookie-input-performance');
  const form = $$('.js-cookie-form');

  const cookieNotice = $$('.js-popup-cookie-notice');

  if (cookieNotice) {
    cookieNotice.parentNode.removeChild(cookieNotice);
  }

  form.addEventListener('change', e => {
    const { target } = e;

    if (target instanceof HTMLInputElement && e.target.type === 'checkbox') {
      const label = target.nextElementSibling.firstElementChild;

      label.textContent = target.checked ? 'Enabled' : 'Disabled';
      label.classList.remove(target.checked ? 'mod-disabled' : 'mod-enabled');
      label.classList.add(target.checked ? 'mod-enabled' : 'mod-disabled');
    }
  });

  form.addEventListener('submit', e => {
    var advertising = false;
    var functional = false;
    var performance = false;

    e.preventDefault();

    if (e.target.submitted === 'accept') {
      advertising = true;
      functional = true;
      performance = true;
    } else if (e.target.submitted === 'reject') {
      advertising = false;
      functional = false;
      performance = false;
    } else {
      advertising = advertisingInput.checked;
      functional = functionalInput.checked;
      performance = performanceInput.checked;
    }

    const newCookieSettings = { advertising, functional, performance };

    consentGranted(performance, advertising);
    updateCookiePrefs(JSON.stringify(newCookieSettings));
    window.cookieValues = newCookieSettings;

    $$('main')?.focus();
    MicroModal.close('modal');
  });
};

window.ptiCookie = cookieModal;

function cookieNotice() {
  const cookieFormClass = 'js-cookie-banner-form';
  const cookieNoticeClass = 'js-popup-cookie-notice';

  if (!(window.cookieValues === null)) {
    return;
  }

  document.body.appendChild(
    makeHtml(`<div class="js-popup-cookie-notice cookie-notice" tabindex="-1">
        <form class="cookie-simple-form ${cookieFormClass}">
          <div>
            <h2 class="cookie-detail-section-heading">Cookies</h2>
            <p class="cookie-notice-text">${cookieMessage}</p>
          </div>

          <div class="cookie-notice-button-container">
            <button class="cookie-notice-button" onclick="this.form.submitted=this.value;" value="accept" type="submit" aria-label="Accept cookies">Accept All</button>
            <button class="cookie-notice-button" onclick="this.form.submitted=this.value;" value="reject" type="submit" aria-label="Reject cookies">Reject All</button>
            <button class="cookie-notice-button mod-secondary" onclick="javascript: window.ptiCookie();" type="button" aria-label="Customise cookies">Customise</button>
          </div>
        </form>
      </div>`)
  );

  document.querySelector(`.${cookieFormClass}`)?.addEventListener('submit', e => {
    var advertising = false;
    var functional = false;
    var performance = false;

    e.preventDefault();

    if (e.target.submitted === 'accept') {
      advertising = true;
      functional = true;
      performance = true;
    }

    consentGranted(performance, advertising);

    const updatedCookiePrefs = {
      advertising,
      functional,
      performance
    };

    updateCookiePrefs(JSON.stringify(updatedCookiePrefs));
    window.cookieValues = updatedCookiePrefs;

    getDomElement(`.${cookieNoticeClass}`).map(el => el.parentNode.removeChild(el));
    getDomElement('main').map(el => el.focus());
  });
}

/**
 * @param {boolean} performanceConsentGiven
 * @param {boolean} advertisingConsentGiven
 * @returns {void}
 */
function consentGranted(performanceConsentGiven, advertisingConsentGiven) {
  if (window.gtag) {
    window.gtag('consent', 'update', {
      // eslint-disable-next-line camelcase
      analytics_storage: performanceConsentGiven ? 'granted' : 'denied',
      ad_storage: advertisingConsentGiven ? 'granted' : 'denied'
    });
  }
}

export { cookieModal, cookieNotice };
