/* eslint-disable require-unicode-regexp, no-param-reassign, array-callback-return, import/no-unassigned-import, no-unused-vars, array-callback-return, id-length, no-shadow, import/max-dependencies, array-callback-return, consistent-return */
// @ts-check
import 'core-js/modules/es.symbol.iterator';
import 'core-js/stable/promise';
import 'core-js/stable/object/entries';
import 'core-js/stable/object/assign';
import 'core-js/stable/string/pad-start';
import 'unfetch/polyfill/index';

import 'flatpickr/dist/flatpickr.css';

import MicroModal from 'micromodal';
import { tns } from 'tiny-slider/src/tiny-slider';
import map from 'ramda/src/map';
import ifElse from 'ramda/src/ifElse';
import 'tiny-slider/dist/tiny-slider.css';
import './css/index.css';

import {
  $$,
  addListener,
  delay,
  isFunction,
  getDisplayTime,
  getDomElement,
  getDomElements,
  percentageInViewport,
  pause,
  parseIntSafe,
  play,
} from './utils';
import { startTracking } from './tracking';
import { cookieModal, cookieNotice } from './cookie';
import { formBuilder } from './form-builder';

startTracking();

/* eslint-disable */
window.addEventListener('DOMContentLoaded', () => {
  formBuilder();
  cookieNotice();

  getDomElement('.js-popup-cookie-notice').map(el => el.focus());
  getDomElement('.js-show-cookie-prefs').map(addListener('click', cookieModal));
});
/* eslint-enable */

const navEl = $$('.js-nav');
const responsiveMenuButton = $$('.nav-menu-button');
const skipLink = $$('.js-skip-link');
const mainEl = $$('main');

if (responsiveMenuButton) {
  responsiveMenuButton.addEventListener('click', evnt => {
    evnt.stopPropagation();
    navEl.classList.toggle('is-open');
    navEl.setAttribute('aria-expanded', true);
  });
}

mainEl.addEventListener('blur', () => mainEl.removeAttribute('tabindex'));

skipLink.addEventListener('click', () => {
  mainEl.setAttribute('tabindex', -1);
  mainEl.focus();
});

/**
 * @param {HTMLVideoElement} video
 * @returns {string}
 */
const videoMarkUp = video => {
  const videoURL = video.getAttribute('data-video-url');
  const videoPoster = video.getAttribute('data-video-poster-url');
  const videoUKSub = video.getAttribute('data-video-uk-subtitles');
  const videoUSSub = video.getAttribute('data-video-us-subtitles');

  return `
  <video class="video mod-popup" autoplay controls controlsList="nodownload" ${
    videoPoster ? `poster=${videoPoster}` : ``
  }>
    <source src="${videoURL}" type="video/mp4">
      ${
        videoUKSub
          ? `<track label="UK English" kind="subtitles" srclang="en" src="${videoUKSub}" default>`
          : ``
      }

      ${
        videoUSSub
          ? `<track label="US English" kind="subtitles" srclang="en" src="${videoUSSub}">`
          : ``
      }
  </video>
`;
};

const setContainerPadding = () => {
  getDomElement('.header').map(header => {
    getDomElement('.app-shell-content-container').map(container => {
      // eslint-disable-next-line no-param-reassign
      container.style.marginTop = `${header.getBoundingClientRect().height}px`;
    });
  });
};

setContainerPadding();

const setModalContent = ({ title = '', content = '' }) => {
  document.querySelector('#modal-title').textContent = title;
  document.querySelector('#modal-content').innerHTML = content;
};

document.addEventListener('keydown', ({ keyCode }) => {
  if (keyCode === 27) {
    setModalContent({});
  }
});

document.addEventListener('click', ({ target }) => {
  if (target instanceof HTMLVideoElement) {
    if (target.hasAttribute('data-video-url')) {
      const videoTitle = target.getAttribute('data-video-title');

      setModalContent({
        content: videoMarkUp(target),
        title: videoTitle
      });

      MicroModal.show('modal');
    }

    if (target.hasAttribute('data-micromodal-close')) {
      setModalContent({});
    }
  }
});

(function bookADemo() {
  const MAX_HEIGHT = 760;
  const MAX_WIDTH = 1025;

  function getIframeSizes(_event) {
    getDomElement('.js-demo-iframe').map(iframe => {
      const width = window.innerWidth > 1085 ? MAX_WIDTH : window.innerWidth - 50;
      const height = window.innerHeight > 1085 ? MAX_HEIGHT : window.innerHeight - 200;

      iframe.style.height = `${height}px`;
      iframe.style.width = `${width}px`;
    })
  }

  window.addEventListener('resize', getIframeSizes)

  getDomElement('.js-book-a-demo').map(addListener('click', () => {
    setModalContent({
      content: `<iframe class="js-demo-iframe" src="https://meetings-eu1.hubspot.com/pagetiger/tigertour" style="border: none;"></iframe>`,
      title: 'Book a Demo'
    });

    getIframeSizes();

    MicroModal.show('modal');

    if (isFunction(window.sendPtiEventData)) {
      window.sendPtiEventData(null, false, 'Book a demo:clicked', '');
    }
  }));
})();


/* PLAY VIDEOS ON LOAD & LOOP & PLAY / PAUSE VIDEOS ON SCROLL */
(function setupVideoPlayback() {
  const isInViewport = percentageInViewport(100);

  // Auto play video's on page load
  getDomElements('.autoplay-on-scroll').filter(isInViewport).map(play);

  getDomElements('video[data-loop-video]').map(video =>
    video.addEventListener('ended', e => e.target.play())
  );

  const videos = getDomElements('.autoplay-on-scroll');
  const toggleVideoState = ifElse(isInViewport, play, pause);

  window.addEventListener('scroll', () => map(toggleVideoState, videos), false);
  window.addEventListener('resize', () => map(toggleVideoState, videos), false);
})();

/* SET THE UNDERLINE FOR THE CURRENT PAGE */
(function setActivePage() {
  const highlightActivePage = (pathname, navItems) => {
    // If we're on the homepage, bail.
    if (pathname === '/') return;


    // If we don't find what were looking for, make sure the path is something that won't match
    const firstPartOfPathname = pathname.match(/[a-z]+/) || 'not-a-known-page';
    const eventsHref = `support/events`;
    const filterFn = pathname.includes(eventsHref)
      ? el => el.href.includes(eventsHref)
      : el => el.href.includes(firstPartOfPathname) && !el.href.includes(eventsHref);

    // If we have a `/support/events` in our collection, find it and and apply the
    // the attribute to only that element.
    navItems
      .filter(filterFn)
      .map(el => el.setAttribute('aria-current', 'page'));
  };

  highlightActivePage(window.location.pathname, getDomElements('a.nav-item-text'));
})();


/* SET THE UNDERLINE FOR THE CURRENT PAGE */
(function convertServicePages() {
  const flipPage = (pathname, main) => {
    if (pathname.indexOf('terms-and-policies') > 0 && main) {
      main.classList.add('mod-flip');
    }
  };

  flipPage(window.location.pathname, mainEl);
})();

const displayCountryChangeError = () => {
  getDomElement('.js-country-form-error').map(el => {
    el.style.display = 'block';
    el.textContent = 'There was a problem with changing your country.';
    el.focus();
  });
};

const processResponse = response =>
  new Promise((res, rej) =>
    response.ok && response.status === 200 ? res(response) : rej(response)
  );

const requestCountryChange = countryCode => {
  fetch(`/api/country-change/${countryCode}`, {
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  })
    .then(processResponse)
    // If you pass in the function reference, it will errror with an invalid invocation.
    .then(() => window.location.reload())
    .catch(displayCountryChangeError);
};

(function handleCountryChange() {
  getDomElement('.js-handle-country-change').map(countrySelectEl => {
    countrySelectEl.addEventListener('change', ({ target }) => {
      parseIntSafe(target.value).either(displayCountryChangeError, requestCountryChange);
    });
  });
})();


(function() {
  getDomElements('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      const linkLocation = this.getAttribute('href') ?? '#';

      getDomElement(linkLocation).map(el => {
        el.scrollIntoView({
          behavior: 'smooth'
        });
      })
    });
  });
})();

delay(250)
  .then(() => {
    import(/* webpackChunkName: "animations" */ './animations')
      .then(({ loadAnimations }) => loadAnimations())
      .catch(console.error);

    window.sliders.map(window.slider.tns);
  })
  .catch(console.error);

/**
 *
 * @param {number} hours
 * @returns {string}
 */
const getHourText = hours => {
  if (hours === 0) {
    return '';
  }

  return hours === 1 ? `${hours} hour` : `${hours} hours`;
};

// ==============
// WEBINAR BANNER
// ==============

const webinarStorageName = 'webinar';
const isCookieBannerHidden = localStorage.getItem(webinarStorageName) === 'true';
const showClassName = 'mod-show';
const arrowDownClassName = 'mod-down';
const cookieBannerClass = 'js-popup-cookie-notice';
const toggleWebinarButtonClass = 'js-toggle-webinar-banner';
const toggleWebinarButton = getDomElement(`.${toggleWebinarButtonClass}`);
const webinarBanner = getDomElement('.js-webinar-banner');
const webinarBannerArrow = getDomElement('.webinar-toggle-arrow');
const now = new Date().getTime();
const fiveMinutesMilli = 1000 * 60 * 5;

const getNextWebinarText = webinars => {
  const [firstEvent] = webinars.sort(
    (timeA, timeB) => new Date(timeA.time).getTime() - new Date(timeB.time).getTime()
  );

  const firstEventTime = new Date(firstEvent.time).getTime();
  const diff = firstEventTime - now;
  const minutes = Math.floor(diff / 1000 / 60) % 60;
  const hours = Math.floor(diff / 1000 / 60 / 60) % 24;
  const minuteText = minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;

  if (diff <= fiveMinutesMilli) {
    return `Our next webinar starts at ${getDisplayTime(firstEvent.time)}`;
  }

  if (minutes === 0) {
    return `Next webinar in ${getHourText(hours)}`;
  }

  return `Next webinar in ${getHourText(hours)} ${hours === 0 ? `` : `and`} ${minuteText}`;
};

webinarBannerArrow.map(el => {
  if (isCookieBannerHidden) {
    el.classList.add(arrowDownClassName);
  }
});

webinarBanner.map(
  /** @param {HTMLElement} webBanner */ webBanner => {
    fetch('/api/webinars')
      .then(res => res.json())
      .then(webinars => {
        if (webinars.length === 0) {
          return;
        }

        getDomElement('.js-next-webinar').map(el => {
          el.textContent = getNextWebinarText(webinars);
        });

        if (!isCookieBannerHidden) {
          delay(1000).then(() => {
            getDomElement(`.${cookieBannerClass}`).map(
              el => (el.style.transform = `translateY(-70px)`)
            );

            webBanner.removeAttribute('aria-hidden');
            webBanner.classList.add('mod-show');
          });
        }

        toggleWebinarButton.map(el => el.classList.add(showClassName));
      })
      .catch(console.error);
  }
);

const toggleWebinarBanner = () => {
  webinarBanner.map(
    /** @param {HTMLElement} banner */
    banner => {
      const bannerVisible = banner.classList.contains(showClassName);

      localStorage.setItem(webinarStorageName, bannerVisible.toString());

      getDomElement(`.${cookieBannerClass}`).map(el => {
        el.style.transform = `translateY(${bannerVisible ? '0' : '-70px'})`;
      });

      webinarBannerArrow.map(el => el.classList.toggle(arrowDownClassName));
      banner.setAttribute('aria-hidden', bannerVisible.toString());
      banner.classList.toggle(showClassName);
    }
  );
};

toggleWebinarButton.map(addListener('click', toggleWebinarBanner));
