/* eslint-disable no-param-reassign, max-lines, id-length, id-blacklist, no-shadow,import/no-unassigned-import, array-callback-return */
// @ts-check
import flatpickr from 'flatpickr';

/** @type {string} */
const grrrAPI = window.GRR_API ? window.GRR_API : 'https://grrr.pagetiger.com';

import {
  addListener,
  base64DecodeToUtf8,
  delay,
  fireConversion,
  getDomElement,
  getDomElements,
  isFunction,
  makeHtml,
  setLocation
} from './utils';

/**
 * @typedef {Object} FormResponse
 * @property {string} successMessage
 * @property {string?} redirectUrl
 */

/**
 * @typedef {Object} Section
 * @property {string} type
 * @property {string} name
 * @property {string} title
 * @property {string} description
 */

/**
 * @typedef {Object} Field
 * @property {number} id
 * @property {string} type
 * @property {string} title
 * @property {string} description
 * @property {number} typeId
 * @property {boolean} required
 * @property {string} defaultValue
 * @property {?string} allowedValues
 * @property {?number} maxValue
 * @property {?number} minValue
 * @property {string} width
 */

/**
 * @typedef {Object} FormData
 * @property {string} formId
 * @property {(Section|Field)[]} formData
 * @property {string} formConversionId
 */

/**
 * @typedef {Object} FormDataSubmit
 * @property {?string} conversionId
 * @property {FormData} fields
 * @property {number} formID
 * @property {string} emailToTrack
 */

/**
 * @returns {void}
 */
const cleanFormErrors = () => {
  getDomElements('.js-form-error').map(
    /** @param {HTMLElement} el */
    el => {
      el.parentNode.removeChild(el);
    }
  );

  getDomElements('[aria-invalid="true"]').map(
    /** @param {HTMLInputElement} el */
    el => {
      el.removeAttribute('aria-invalid');
    }
  );
};

/**
 * @param {string} formId - The ID of the form
 * @param {FormResponse} formResponse - The success message to display
 */
const showSuccessMessage = (formId, formResponse) => {
  getDomElement(`.js-form-${formId}`).map(
    /** @param {HTMLFormElement} form */
    form => {
      form.innerHTML = '';
    }
  );

  getDomElement(`.js-form-message-${formId}`).map(
    /** @param {HTMLElement} messageContainer */
    messageContainer => {
      messageContainer.innerHTML = '';
      messageContainer.classList.remove('mod-hidden');
      messageContainer.classList.remove('mod-error');
      messageContainer.classList.add('mod-success');

      messageContainer.appendChild(
        makeHtml(`
          <div>
            <h4 class="dynamic-form-message-title">${formResponse.successMessage}</h4>
          </div>`)
      );

      messageContainer.focus();
    }
  );
};

/**
 * @param {string} formId - The ID of the form
 * @param {(string|any[])} message - The success message to display
 */
const showErrorMessage = (formId, message) => {
  cleanFormErrors();

  getDomElement(`.js-form-button-${formId}`).map(
    /** @param {HTMLButtonElement} button */
    button => {
      button.removeAttribute('disabled');
    }
  );

  if (typeof message === 'string') {
    getDomElement(`.js-form-message-${formId}`).map(
      /** @param {HTMLElement} messageContainer */
      messageContainer => {
        messageContainer.innerHTML = '';
        messageContainer.classList.remove('mod-hidden');
        messageContainer.classList.add('mod-error');

        messageContainer.appendChild(
          makeHtml(`<h4 class="dynamic-form-message-title">${message}</h4>`)
        );

        messageContainer.focus();
      }
    );

    return;
  }

  const errorMessages = Object.entries(message);
  const errorMessagesLength = errorMessages.length;

  errorMessages.map(([fieldId, errorMessage]) => {
    getDomElement(`#field-${formId}-${fieldId}`).map(
      /** @param {HTMLInputElement} input */
      input => input.setAttribute('aria-invalid', 'true')
    );

    getDomElement(`[for="field-${formId}-${fieldId}"]`).map(
      /** @param {HTMLElement} field */
      field => {
        field.appendChild(makeHtml(fieldError(errorMessage)));
      }
    );
  });

  getDomElement(`.js-form-message-${formId}`).map(
    /** @param {HTMLElement} messageContainer */
    messageContainer => {
      messageContainer.innerHTML = '';
      messageContainer.classList.remove('mod-hidden');
      messageContainer.classList.add('mod-error');

      const errorGeneralMessage =
        errorMessagesLength > 1
          ? `There were ${errorMessagesLength} errors found in the information you submitted.`
          : `There was ${errorMessagesLength} error found in the information you submitted.`;

      const errorList = `
    <ul class="dynamic-form-list" data-t="form-error-list-${formId}">
    ${errorMessages
      .map(([fieldId, errorMessage]) => {
        const fieldName = getDomElement(`.js-field-name-${formId}-${fieldId}`)
          .map(el => el.textContent)
          .option('');

        return `<li>
            <span class="dynamic-form-error">${fieldName}:
              <a class="dynamic-form-error-link" href="#field-${formId}-${fieldId}"> ${errorMessage}</a>
            </span>
            </li>`;
      })
      .join('')}</ul>`;

      messageContainer.appendChild(
        makeHtml(`
      <h4 class="dynamic-form-message-title">${errorGeneralMessage}</h4>
      ${errorList}
      `)
      );

      messageContainer.focus();
    }
  );
};

/**
 * @returns {Promise<string|null>}
 */
const getGrrToken = () =>
  fetch(`${grrrAPI}/api/t/id`, {
    mode: 'cors',
    credentials: 'include'
  })
    .then(res => (res.ok ? res.json() : null))
    .catch(console.error);

/**
 * @param {FormDataSubmit} formData
 * @returns {Promise<void>}
 */
const submitForm = formData => {
  const API_TOKEN = '6Lfi1KYZAAAAAMZZMOFEW904Tj9Ox-EFOzYO-Kq8';

  return Promise.all([
    getGrrToken(),
    window.grecaptcha.execute(API_TOKEN, { action: 'submit' })
  ]).then(([grrrToken, captchaToken]) => {
    fetch(`/api/form/submit`, {
      body: JSON.stringify({
        ...formData,
        grrrToken,
        captchaToken
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(
        /**
         * @param {Response} res
         * @returns {Promise<FormResponse>}  */
        res => {
          const jsonContent = res.json();

          if (res.status >= 200 && res.status < 300) {
            return jsonContent;
          }

          if (res.status >= 400 && res.status < 500) {
            return jsonContent.then(content => Promise.reject(content));
          }

          return Promise.reject(new Error('Something went wrong'));
        }
      )
      .then(formResponse => {
        if (formData.emailToTrack && isFunction(window.sendPtiEventData)) {
          window.sendPtiEventData(formData.emailToTrack, true, null, null);
        }

        delay(300).then(() => {
          fireConversion(window.gtag, window.GADS, formData.conversionId, '', () => {});
          if (formResponse.redirectUrl) {
            return setLocation(formResponse.redirectUrl);
          }

          showSuccessMessage(formData.formID.toString(), formResponse);
        });
      })
      .catch(failureMessage => {
        if (failureMessage instanceof Error) {
          showErrorMessage(formData.formID.toString(), 'Unable to register or haven\'t received your confirmation email? Please feel free to contact sales@pagetiger.com and a member of the team will assist');
        } else {
          showErrorMessage(formData.formID.toString(), failureMessage);
        }
      });
  });
};

/**
 * @param {string} errorMessage - The ID of the form
 * @returns {string}
 */
const fieldError = errorMessage =>
  `<span class="dynamic-form-element-error js-form-error">${errorMessage}</span>`;

/**
 * @param {'s'|'m'|'l'} size
 * @returns {string}
 */
const mapWidthToClassName = size => {
  switch (size.toLowerCase()) {
    case 's':
      return 'is-medium';

    case 'm':
      return 'is-large';

    case 'l':
      return 'is-full';

    default:
      return 'is-medium';
  }
};

const requiredSymbol = `<span aria-hidden="true" class="dynamic-form-required">*</span>`;

/**
 * @param {number} formId
 * @param {(Field|Section)} formItem
 * @returns {string}
 */
const renderFormItems = (formId, formItem) => {
  if (formItem.type === 'section') {
    return formHeading(formItem);
  }

  switch (formItem.typeId) {
    case 4:
      return textArea(formId, formItem);

    case 7:
      return dropdownList(formId, formItem);

    case 8:
      return checkbox(formId, formItem);

    default:
      return textInput(formId, formItem);
  }
};

/**
 * @param {number} formId
 * @param {Field} fieldData
 * @returns {string}
 */
const renderFormLabel = (
  formId,
  fieldData
) => `<label class="form-element-label" for="field-${formId}-${fieldData.id}">
    <span class="form-element-label-text js-field-name-${formId}-${fieldData.id}">${
  fieldData.title
}</span>
        ${fieldData.required ? requiredSymbol : ``}
        ${
          fieldData.description.length
            ? `<p class="form-element-label-description">${fieldData.description}</p>`
            : ``
        }
  </label>`;

/**
 * @param {Field} sectionData
 * @returns {string}
 */
const formHeading = sectionData => `
    <div class="dynamic-form-heading">
      <h4 class="dynamic-form-title">${sectionData.title}</h4>
      <span class="dynamic-form-description">${sectionData.description}</span>
    </div>`;

/**
 * @param {number} formId
 * @param {Field} fieldData
 * @returns {string}
 */
const dropdownList = (formId, fieldData) => {
  const hasSelectedItem = fieldData.defaultValue !== '';

  return `<div class="form-element">
    ${renderFormLabel(formId, fieldData)}
    <select class="form-element-select ${mapWidthToClassName(
      fieldData.width
    )}" id="field-${formId}-${fieldData.id}" name="${fieldData.id}" data-t="${fieldData.id}">
      ${hasSelectedItem ? '' : `<option value="" disabled selected hidden>Please select…</option>`}
      ${fieldData.allowedValues
        .map(
          item => `<option ${item === fieldData.defaultValue ? `selected` : ``}>${item}</option>`
        )
        .join('')}
    </select>
  </div>`;
};

/**
 * @param {number} formId
 * @param {Field} fieldData
 * @returns {string}
 */
const checkbox = (formId, fieldData) => `
  <div class="form-element mod-flex">
    <input class="form-element-checkbox mod-inline" type="checkbox" ${
      fieldData.defaultValue === 'true' ? `checked` : ``
    } ${fieldData.required ? `required` : ``} name="${fieldData.id}" id="field-${formId}-${
  fieldData.id
}" data-t="${fieldData.id}">
    ${renderFormLabel(formId, fieldData)}
  </div>`;

/**
 * @param {number} typeId
 * @param {string} defaultValue
 * @returns {string}
 */
const getInputAttrs = (typeId, defaultValue) => {
  if (typeId === 1 || typeId === 2) {
    return `type="email" ${typeId === 1 ? `data-myemail="true"` : ``}`;
  } else if (typeId === 5) {
    return `type="text" inputmode="numeric" pattern="[0-9]*" min="5" max="10"`;
  } else if (typeId === 6) {
    return `type="date" data-date-input ${defaultValue ? `data-init-date="${defaultValue}"` : ``}`;
  } else if (typeId === 9) {
    return `type="tel"`;
  }

  return 'type="text"';
};

/**
 * @param {number} formId
 * @param {Field} fieldData
 * @returns {string}
 */
const textInput = (formId, fieldData) => `
  <div class="form-element">
    ${renderFormLabel(formId, fieldData)}
    <input ${getInputAttrs(
      fieldData.typeId,
      fieldData.defaultValue
    )} autocomplete="off" class="form-element-input ${mapWidthToClassName(
  fieldData.width
)}" name="${fieldData.id}" id="field-${formId}-${fieldData.id}" ${
  fieldData.required ? `required` : ``
} data-t="${fieldData.id}" type="${fieldData.type}" value="${fieldData.defaultValue || ''}">
  </div>`;

/**
 * @param {number} formId
 * @param {Field} fieldData
 */
const textArea = (formId, fieldData) => `
  <div class="form-element">
  ${renderFormLabel(formId, fieldData)}
    <textarea class="form-element-input mod-textarea ${mapWidthToClassName(
      fieldData.width
    )}"" name="${fieldData.id}" id="field-${formId}-${fieldData.id}" ${
  fieldData.required ? `required` : ``
} type="${fieldData.type}">${fieldData.defaultValue}</textarea>
  </div>`;

/**
 * @param {FormData} formData
 * @returns {string}
 */
const renderForm = formData => `
  <div class="js-form-message-${
    formData.formId
  } dynamic-form-message mod-hidden" aria-live="polite" tabindex="-1" data-t="form-message-${
  formData.formId
}"></div>

  <form data-id="${formData.formId}" data-conversion-id="${
  formData.formConversionId
}" class="js-form js-form-${formData.formId}">
    ${formData.formData.map(formItem => renderFormItems(formData.formId, formItem)).join('')}

    <div class="form-element">
      <input class="form-element-checkbox js-ignore" type="checkbox" required id="field-accept-terms-${
        formData.formId
      }" data-t="form-accept-${formData.formId}">
      <label class="form-element-label" for="field-accept-terms-${formData.formId}">
        <span class="form-element-label-text">
          Accept PageTiger's <a class="dynamic-form-link" target="_blank" rel="noopener noreferrer" href="https://www.pagetiger.com/terms-and-policies/privacy-notice">Privacy Notice</a>
        </span>
          ${requiredSymbol}
      </label>
    </div>

    <span class="dynamic-form-recapture-message">
      This site is protected by reCAPTCHA and the Google
      <a class="dynamic-form-link" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a class="dynamic-form-link" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
    </span>

    <button
      type="submit"
      class="button dynamic-form-button primary-button js-form-button-${
        formData.formId
      }" data-t="form-submit-${formData.formId}">Submit</button>
  </form>`;

/**
 * @param {string} formId
 * @returns {void}
 */
const removeSelectValidityOnChange = formId => {
  getDomElement(`.js-form-${formId}`).map(
    addListener('change', ({ target }) => {
      if (target.nodeName === 'SELECT') {
        target.setCustomValidity('');
      }
    })
  );
};

/**
 * @param {string} formId
 * @returns {Boolean}
 */
const validateSelects = formId =>
  getDomElements(`.js-form-${formId} select`).reduce((acc, selectEl) => {
    if (selectEl.value === '') {
      selectEl.setCustomValidity('Please select an option');
      selectEl.reportValidity();

      return false;
    }

    return acc;
  }, true);

/**
 * @param {HTMLElement[]} forms
 * @returns {void}
 */
const outputRecaptcha = forms => {
  if (forms.length === 0) {
    return;
  }

  const recaptcha = document.createElement('script');

  recaptcha.setAttribute(
    'src',
    'https://www.google.com/recaptcha/api.js?render=6Lfi1KYZAAAAAMZZMOFEW904Tj9Ox-EFOzYO-Kq8'
  );

  recaptcha.setAttribute('defer', 'true');

  document.body.appendChild(recaptcha);
};

const formBuilder = () => {
  const forms = getDomElements('.js-dynamic-form');

  outputRecaptcha(forms);

  forms.map(form => {
    const { formConversionId = '', formId } = form.dataset;

    getDomElement(`.js-form-id-${formId}`).map(
      /** @param {HTMLFormElement} container */
      container => {
        container.appendChild(
          makeHtml(
            renderForm({
              formConversionId,
              formData: JSON.parse(base64DecodeToUtf8(form.dataset.json)),
              formId
            })
          )
        );
      }
    );

    removeSelectValidityOnChange(formId);

    getDomElements(`.js-form-id-${formId} [data-date-input]`).map(
      /** @param {HTMLInputElement} dateEl */
      dateEl => {
        const { initDate } = dateEl.dataset;
        const defaultDate = initDate ? new Date(initDate) : new Date();

        flatpickr(dateEl, { defaultDate });
      }
    );
  });

  document.addEventListener('submit', submitEvent => {
    if (submitEvent.target.classList.contains('js-form')) {
      submitEvent.preventDefault();
      submitEvent.stopPropagation();

      const { conversionId, id: formId } = submitEvent.target.dataset;
      const allSelectsValid = validateSelects(formId);

      if (!allSelectsValid) {
        return;
      }

      var emailToTrack = '';
      const formInputSelectors = `
      .js-form-${formId} input:not(.js-ignore),
      .js-form-${formId} textarea,
      .js-form-${formId} select`;
      const formData = getDomElements(formInputSelectors).map(
        /**
         * @param {HTMLInputElement} el
         * @returns {{id: string, value: string }}
         */
        el => {
          if (el.type === 'checkbox') {
            return { id: el.name, value: el.checked };
          }

          if (el.dataset.myemail === 'true' && el.value.length > 3) {
            emailToTrack = el.value;
          }

          return { id: el.name, value: el.value };
        }
      );

      getDomElement(`.js-form-button-${formId}`).map(
        /** @param {HTMLButtonElement} button */
        button => {
          button.setAttribute('disabled', 'true');
        }
      );

      submitForm({
        conversionId,
        emailToTrack,
        fields: formData,
        formID: formId
      });
    }
  });
};

export {
  formBuilder,
  // Exporting these for tests only
  showErrorMessage,
  showSuccessMessage
};
